import { Mainnet, Avalanche, Rinkeby, AvalancheTestnet } from "@usedapp/core";
import { BigNumber } from "ethers";

export const NETWORK_ENV = process.env.REACT_APP_ENV || "development";

export const dAppConfig = {
  production: {
    readOnlyChainId: Mainnet.chainId,
    readOnlyUrls: {
      [Mainnet.chainId]:
        "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      [Avalanche.chainId]: "https://api.avax.network/ext/bc/C/rpc",
    },
    networks: [Mainnet],
    autoConnect: true,
    multicallAddresses: {
      [Avalanche.chainId]: "0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3",
    },
  },
  development: {
    readOnlyChainId: Rinkeby.chainId,
    readOnlyUrls: {
      [Rinkeby.chainId]:
        "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      [AvalancheTestnet.chainId]: "https://api.avax-test.network/ext/bc/C/rpc",
    },
    networks: [Rinkeby],
    autoConnect: true,
    multicallAddresses: {
      [AvalancheTestnet.chainId]: "0xccc75e78Dce6A20bCCa3a30deB23Cb4D23df993a",
    },
  },
}[NETWORK_ENV] as any;

interface INetworkConfig {
  contracts: {
    shroom: string;
    shroomChainId: number;
    niftyx: string;
    niftyxChainId: number;
  };
  avax: { rpc: string; chainId: number };
}

export const config = {
  production: {
    contracts: {
      shroom: "0xed0439eacf4c4965ae4613d77a5c2efe10e5f183",
      shroomChainId: Mainnet.chainId,
      niftyx: "0x32591ed516aa6f67e338cb9f88989f3fb91a860b",
      niftyxChainId: Avalanche.chainId,
    },
    avax: {
      rpc: "https://api.avax.network/ext/bc/C/rpc",
      chainId: Avalanche.chainId,
    },
  },
  development: {
    contracts: {
      shroom: "0x4A94EC97E059069C7e41F8f6CDF7F963d115E632",
      shroomChainId: Rinkeby.chainId,
      niftyx: "0x282d945e015858934b0d7caa7d1b9796759b3ef3",
      niftyxChainId: AvalancheTestnet.chainId,
    },
    avax: {
      rpc: "https://api.avax-test.network/ext/bc/C/rpc",
      chainId: AvalancheTestnet.chainId,
    },
  },
}[NETWORK_ENV] as Required<INetworkConfig>;

export const ZERO = BigNumber.from(0);
export const ETHER_DECIMALS = 18;
export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";
export const BAD_ADDRESS = "0xbad0000000000000000000000000000000000000";
