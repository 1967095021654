import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";

import { DAppProvider } from "@usedapp/core";
import { SnackbarProvider } from "notistack";

import React from "react";
import { MainLayout } from "./src/layouts";
import HomePage from "./src/pages/HomePage";
import { dAppConfig } from "src/config/constants";

const theme = createMuiTheme({});

function App() {
  return (
    <DAppProvider config={dAppConfig}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <MainLayout>
            <HomePage />
          </MainLayout>
        </SnackbarProvider>
      </ThemeProvider>
    </DAppProvider>
  );
}

export default App;
