import React from "react";
import { Button, Theme, makeStyles } from "@material-ui/core";

import { Header } from "./components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 80,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 60,
    },
  },
  content: {
    minHeight: 400,
    padding: 24,
    height: `calc(100vh - 160px)`,
    [theme.breakpoints.down("sm")]: {
      padding: 12,
      height: `calc(100vh - 90px)`,
    },
  },
}));

interface IProps {
  children: React.ReactNode[] | React.ReactNode;
}

export const MainLayout = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};
