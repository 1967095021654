import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { useTokenBalance, useEthers, useContractFunction } from "@usedapp/core";
import { useEffect, useState } from "react";
import {
  config,
  ETHER_DECIMALS,
  BAD_ADDRESS,
  ZERO,
} from "src/config/constants";
import { formatBigNumber, numberWithCommas } from "src/utils";
import { useSnackbar } from "notistack";
import erc20Abi from "src/abis/erc20.json";
import { BigNumber, Contract } from "ethers";
import { formatEther, parseEther } from "ethers/lib/utils";
import { JsonRpcProvider } from "@ethersproject/providers";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  content: {
    maxWidth: 380,
    width: "95%",
    margin: "24px auto",
  },
  logos: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 24,
  },
  logo: {
    height: 64,
  },
  arrow: {
    height: 20,
    backgroundImage: "url(/assets/pngs/arrow.png)",
    width: 100,
    marginRight: 24,
    marginLeft: 12,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  main: {
    display: "flex",
    justifyContent: "center",
  },
  mainItem: {
    flex: 1,
    "&+&": {
      marginLeft: 16,
    },
  },
  mainLabel: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: 600,
  },
  mainItemContent: {
    padding: 12,
    backgroundColor: "#eee",
    borderRadius: 8,
    fontWeight: 600,
    lineHeight: "1.8",
  },
  helper: {
    textAlign: "center",
    fontSize: 14,
    marginTop: 32,
  },
  spinner: {
    textAlign: "center",
  },
  label: {
    fontSize: 14,
  },
}));

enum MigrationStatus {
  None,
  Pending,
  Wait,
  Completed,
}

const HomePage = () => {
  const classes = useStyles();
  const { account, chainId } = useEthers();
  const shroomBalance = useTokenBalance(config.contracts.shroom, account, {
    chainId: config.contracts.shroomChainId,
  });
  const niftyxBalance = useTokenBalance(config.contracts.niftyx, account, {
    chainId: config.contracts.niftyxChainId,
  });

  const [status, setStatus] = useState<MigrationStatus>(MigrationStatus.None);
  const [amountToReceive, setAmountToReceive] = useState(ZERO);
  const { enqueueSnackbar } = useSnackbar();

  const shroomToken = new Contract(config.contracts.shroom, erc20Abi);
  const { state, send } = useContractFunction(shroomToken as any, "transfer");

  const waitUntilConfirmed = (
    amountToReceive: BigNumber,
    onReceived: () => void
  ) => {
    const provider = new JsonRpcProvider(config.avax.rpc, config.avax.chainId);
    const contract = new Contract(config.contracts.niftyx, erc20Abi, provider);

    console.log("=amountToReceive=", formatEther(amountToReceive));

    contract.on("Transfer", async (from, to, value, event) => {
      console.log("====Transfer===", from, to, value, event);
      if (
        to.toLowerCase() === (account || "").toLowerCase() &&
        amountToReceive.eq(BigNumber.from(value))
      ) {
        console.log("===mine");
        const tx = await event.getTransaction();
        console.log("===tx", tx);

        onReceived();
      }
    });
  };

  useEffect(() => {
    if (state.errorMessage) {
      enqueueSnackbar(state.errorMessage, {
        variant: "error",
      });
      setStatus(MigrationStatus.None);
    } else if (state.status === "Success") {
      setStatus(MigrationStatus.Wait);
      // need to wait

      waitUntilConfirmed(amountToReceive, () => {
        setStatus(MigrationStatus.Completed);
        enqueueSnackbar("Migration is done successfully!");
      });
    }
  }, [state]);

  const onMigrate = () => {
    if (chainId !== config.contracts.shroomChainId) {
      return enqueueSnackbar(
        "Please connect to Ethereum network and try again",
        { variant: "error" }
      );
    }
    setStatus(MigrationStatus.Pending);
    setAmountToReceive(() => (shroomBalance || ZERO).mul(153).div(100));

    send(BAD_ADDRESS, shroomBalance);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.logos}>
          <img src="/assets/pngs/eth.png" className={classes.logo} />
          <div className={classes.arrow} />
          <img src="/assets/pngs/avax.png" className={classes.logo} />
        </div>
        <div className={classes.main}>
          <div className={classes.mainItem}>
            <p className={classes.mainLabel}>YOUR SHROOM TOKENS</p>
            <div className={classes.mainItemContent}>
              {numberWithCommas(
                formatBigNumber(shroomBalance || ZERO, ETHER_DECIMALS, 2)
              )}{" "}
              SHROOM
            </div>
          </div>
          <div className={classes.mainItem}>
            <p className={classes.mainLabel}>YOUR NIFTYX TOKENS</p>
            <div className={classes.mainItemContent}>
              {numberWithCommas(
                formatBigNumber(niftyxBalance || ZERO, ETHER_DECIMALS, 2)
              )}{" "}
              NIFTYX
            </div>
          </div>
        </div>
        <br />
        <Button
          fullWidth
          variant="contained"
          onClick={onMigrate}
          disabled={
            !account ||
            !shroomBalance ||
            shroomBalance.isZero() ||
            status === MigrationStatus.Pending ||
            status === MigrationStatus.Wait
          }
        >
          Migrate Tokens
        </Button>
        {(status === MigrationStatus.Pending ||
          status === MigrationStatus.Wait) && (
          <div>
            <div className={classes.spinner}>
              <br />
              <CircularProgress size={24} />
              <p>Processing</p>
            </div>
            <p className={classes.label}>
              * Tokens will be credited after 3 network confirmations
              <br />* You will receive your tokens on the Avalanche network
            </p>
          </div>
        )}
        {status === MigrationStatus.Completed && (
          <div>
            <div className={classes.spinner}>
              <br />
              <p>Completed</p>
            </div>
          </div>
        )}
        {!account && (
          <p className={classes.helper}>Connect your wallet to start</p>
        )}
      </div>
    </div>
  );
};

export default HomePage;
