import { Button, Theme, makeStyles } from "@material-ui/core";
import { shortenAddress, useEthers } from "@usedapp/core";
import { ReactComponent as LogoSvg } from "assets/svgs/logo.svg";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    height: 80,
    boxShadow: "1px 1px 2px #eee",
    padding: "12px 24px",
    [theme.breakpoints.down("sm")]: {
      height: 60,
      padding: "8px 16px",
    },
  },
  content: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  left: { display: "flex", alignItems: "center", height: "100%" },
  right: {},
  logo: {
    height: 36,
    [theme.breakpoints.down("sm")]: {
      height: 24,
    },
  },
  migration: {
    color: "#000",
    marginLeft: 24,
    fontWeight: 600,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      marginLeft: 8,
    },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      minWidth: `48px !important`,
      fontSize: "0.75rem !important",
    },
  },
}));

export const Header = () => {
  const classes = useStyles();
  const { account, activate, deactivate } = useEthers();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMsg, setErrorMsg] = useState("");

  const { error } = useEthers();
  useEffect(() => {
    if (error) {
      setErrorMsg(() => error.message);
    }
  }, [error]);

  useEffect(() => {
    if (errorMsg) enqueueSnackbar(errorMsg, { variant: "error" });
  }, [errorMsg]);

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: "Metamask",
          description: "Connect with the provider in your Browser",
        },
        package: null,
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://bridge.walletconnect.org",
          infuraId: "d8df2cb7844e4a54ab0a782f608749dd",
        },
      },
    };

    const web3Modal = new Web3Modal({
      providerOptions,
    });
    try {
      const provider = await web3Modal.connect();
      await activate(provider);
    } catch (error: any) {
      setErrorMsg(() => error.message);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.left}>
          <LogoSvg className={classes.logo} />
          <p className={classes.migration}>Migration</p>
        </div>
        <div className={classes.right}>
          {account ? (
            <Button
              variant="contained"
              className={classes.button}
              onClick={deactivate}
              style={{ textTransform: "none" }}
            >
              {shortenAddress(account)}
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.button}
              onClick={activateProvider}
              style={{ textTransform: "none" }}
            >
              Connect Wallet
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
