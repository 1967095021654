import { BigNumber, utils } from "ethers";

const { formatUnits } = utils;

export const formatBigNumber = (
  value: BigNumber,
  decimals: number,
  precision = 2
): string => Number(formatUnits(value, decimals)).toFixed(precision);

export const numberWithCommas = (x: number | string) => {
  const splits = x.toString().split(".");
  const first = splits[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (splits.length === 1) return first;
  return [first, splits[1]].join(".");
};
